<template>
  <div class="py-5 mb-5">
    <h1 class="text-primary">Signup</h1>
    <a-form
      layout="vertical"
      ref="formRef"
      :model="formState"
      :rules="formRules"
    >
      <a-form-item label="E-mail" required has-feedback name="account_email">
        <a-input
          v-model:value="formState.account_email"
          placeholder="E-mail Address"
          type="email"
          size="large"
          autocomplete="forgot-pwd-master-email"
        />
      </a-form-item>
      <a-form-item label="Feature" required>
        <a-checkbox-group v-model:value="formState.role_feature">
          <a-checkbox value="ACADEMY">Academy</a-checkbox>
          <a-checkbox value="ELEARNING">E-Learning</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
      <a-form-item>
        <div class="d-flex justify-content-center">
          <a-button :loading="loading" block type="primary" @click="handleRequestFinish" :disabled="isSignUpDisabled" >
            Sign up
          </a-button>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed } from "vue";
import { authService } from "@/_services";
import { useRequest } from "vue-request";

interface FormState {
  account_email: string;
  role_feature: string[];
}

export default defineComponent({
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<any>(null);
    const formState: FormState = reactive({
      account_email: "",
      role_feature: [],
    });

    const formRules = {
      account_email: [
        {
          required: true,
          message: "Please input your e-mail address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input a valid e-mail address",
          trigger: "blur",
        },
      ],

    };

    const { run } = useRequest(authService.signup, {
      manual: true,
      onError: () => {
        loading.value = false;
      },
      onSuccess: (data: any) => {
        alert(data.message);
        loading.value = false;
      },
    });

    const handleRequestFinish = () => {
        loading.value = true;
        run({ ...formState });
    };
    const isSignUpDisabled = computed(() => {
      return !formState.account_email || formState.role_feature.length === 0;
    });
    return {
      formRef,
      formState,
      formRules,
      loading,
      handleRequestFinish,
      isSignUpDisabled
    };
  },
});
</script>

<style scoped>
.py-5 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.mb-5 {
  margin-bottom: 3rem;
}
.text-primary {
  color: #1890ff;
}
</style>
