
import { defineComponent, ref, reactive, computed } from "vue";
import { authService } from "@/_services";
import { useRequest } from "vue-request";

interface FormState {
  account_email: string;
  role_feature: string[];
}

export default defineComponent({
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<any>(null);
    const formState: FormState = reactive({
      account_email: "",
      role_feature: [],
    });

    const formRules = {
      account_email: [
        {
          required: true,
          message: "Please input your e-mail address",
          trigger: "blur",
        },
        {
          type: "email",
          message: "Please input a valid e-mail address",
          trigger: "blur",
        },
      ],

    };

    const { run } = useRequest(authService.signup, {
      manual: true,
      onError: () => {
        loading.value = false;
      },
      onSuccess: (data: any) => {
        alert(data.message);
        loading.value = false;
      },
    });

    const handleRequestFinish = () => {
        loading.value = true;
        run({ ...formState });
    };
    const isSignUpDisabled = computed(() => {
      return !formState.account_email || formState.role_feature.length === 0;
    });
    return {
      formRef,
      formState,
      formRules,
      loading,
      handleRequestFinish,
      isSignUpDisabled
    };
  },
});
